export function getCsrfParams() {
  let csrfParam = document
    .querySelector('meta[name=csrf-param]')
    .getAttribute('content')
  let csrfValue = document
    .querySelector('meta[name=csrf-token]')
    .getAttribute('content')
  return [csrfParam, csrfValue]
}

export function getCsrfParamsAsObject() {
  let [param, value] = getCsrfParams()
  let rv = {}
  rv[param] = value
  return rv
}

export function getCsrfParamsAsJSON() {
  return JSON.stringify(getCsrfParamsAsObject())
}

export function appendCsrfParams(formData) {
  let [n, v] = getCsrfParams()
  formData.append(n, v)
}
